exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-kit-tsx": () => import("./../../../src/pages/media-kit.tsx" /* webpackChunkName: "component---src-pages-media-kit-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog/article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog/list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog/tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */)
}

